<template>
	<div class="article-box">

		<el-col :span="8" style="text-align:left;"><span style="font-size: 20px;font-weight: 300;">学校列表
				({{CountNum}})</span></el-col>
		<el-col :span="8">
			<el-input placeholder="请输入搜索关键字" v-model="keyword">
				<el-button slot="append" icon="el-icon-search" @click="schoolSerchList(1)"></el-button>
			</el-input>
		</el-col>
		<el-col :span="8" style="text-align:right;">
			<el-button type="primary" @click="dialogExcelCard = true" style="margin-right: 35px;">+ 导入卡号
			</el-button>
			<el-button type="primary" @click="dialogExcel = true" style="margin-right: 35px;">+ 导入数据
			</el-button>
			<el-button type="primary" @click="dialogFormVisible = true" style="margin-right: 35px;">+ 添加学校
			</el-button>
			
		</el-col>
		<el-table :data="schoolData" style="width: 100%" v-loading="loading">
			<el-table-column prop="ID" label="学校ID" align="center"></el-table-column>
			<el-table-column prop="SchoolName" label="学校名称" align="center" width="200"></el-table-column>
			<el-table-column prop="TypeName" label="学校类型	" align="center"></el-table-column>
			<el-table-column prop="Account" label="学校账号" align="center" width="150"></el-table-column>
			<el-table-column prop="InsertTime" label="创建时间	" align="center" width="150"></el-table-column>
			<el-table-column prop="AgentName" label="所属代理" align="center"></el-table-column>
			<el-table-column prop="Mobile" label="联系电话	" align="center"></el-table-column>
			<el-table-column prop="kid_head" label="卡号前缀" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.kid_head">{{scope.row.kid_head}}</span>
					<el-button type="primary" size="mini" @click="setCardNo(scope.row)" v-else>设置</el-button>
				</template>
			</el-table-column>
			<el-table-column prop="Remark" label="备注" align="center"></el-table-column>
			<el-table-column label="地区" align="center" width="200">
				<template slot-scope="scope">
					<span>{{scope.row.provinceName + scope.row.cityName + scope.row.areaName}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" align="center" width="400">
				<template slot-scope="scope">
					<el-button type="primary" size="mini" @click="seachLevel(scope.row)">查看年级</el-button>
					<el-button type="primary" size="mini" @click="seachStudent(scope.row)">查看学生</el-button>
					<el-button type="primary" size="mini" @click="setSource(scope.row)">数据设置</el-button>
					<el-button type="primary" size="mini" @click="createCardNo(scope.row)">生成卡号</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="block">
			<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
				layout="prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
			</el-pagination>
		</div>
		<el-dialog title="添加学校" :visible.sync="dialogFormVisible" :destroy-on-close="true" top="50px"
			:close-on-click-modal="false" width="30%">
			<el-form :model="form" ref="form" label-position="right" label-width="170px">
				<el-form-item label="省" style="width: 100%;">
					<el-select v-model="form.Province" placeholder="省" @change="province" style="width: 80%;">
						<el-option v-for="item in provinceList" :key="item.ID" :label="item.Name" :value="item.ID">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市" style="width: 100%;">
					<el-select v-model="form.City" placeholder="市" @change="city" style="width: 80%;">
						<el-option v-for="item in cityList" :key="item.ID" :label="item.Name" :value="item.ID">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区" style="width: 100%;">
					<el-select v-model="form.Area" placeholder="区" style="width: 80%;">
						<el-option v-for="item in areaList" :key="item.ID" :label="item.Name" :value="item.ID">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="类别" style="width: 100%;">
					<el-select v-model="form.TypeId" placeholder="请选择学校类别" style="width: 80%;">
						<el-option v-for="item in categoryList" :key="item.ID" :label="item.TypeName" :value="item.ID">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="级别" style="width: 100%;" v-if="form.TypeId == '1'">
					<el-checkbox-group v-model="classtypelevelData" placeholder="请选择幼儿园级别" style="width: 100%;">
						<el-checkbox v-for="item in classtypelevelList" :label="item.id" :key="item.id"
							style="margin: 0;margin-right: 5px;">{{item.level}}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<!--        <el-form-item label="单独表" style="width: 100%;">
            <el-radio v-model="form.stable" label="0">否</el-radio>
            <el-radio v-model="form.stable" label="1">是</el-radio>
        </el-form-item> -->
				<el-form-item label="学年制" style="width: 100%;">
					<!--            <el-radio v-model="form.xuenian" label="1">1年</el-radio>
            <el-radio v-model="form.xuenian" label="2">2年</el-radio> -->
					<el-radio v-model="form.xuenian" label="3">3年</el-radio>
					<el-radio v-model="form.xuenian" label="4">4年</el-radio>
					<el-radio v-model="form.xuenian" label="5">5年</el-radio>
					<el-radio v-model="form.xuenian" label="6">6年</el-radio>
				</el-form-item>
				<el-form-item label="学校" style="width: 100%;">
					<el-input v-model="form.School" placeholder="请输入学校" style="width:80%"></el-input>
				</el-form-item>
				
				<el-form-item label="数据来源" style="width: 100%;">
					<el-select v-model="form.source" style="width: 80%;">
						<el-option label="API" value="api" />
						<el-option label="U盘" value="upan" />
					</el-select>
				</el-form-item>
				<el-form-item label="传输学生数据" style="width: 100%;" v-if="form.source=='api'">
					<el-select v-model="form.core_data" style="width: 80%;">
						<el-option label="是" value="1" />
						<el-option label="否" value="2" />
					</el-select>
				</el-form-item>
				<el-form-item label="" style="width: 100%;">
					<el-checkbox v-model="form.onlyStuNum" true-label="1" false-label="2">学号创建学生</el-checkbox>
				</el-form-item>
				
				<el-form-item style="width: 100%;" v-if="form.onlyStuNum==1" label="学号位数">
					<el-radio-group v-model="form.numType">
						<el-radio label="4">4-6位</el-radio>
						<el-radio label="9">大于9位</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="短编号生成方式" style="width: 100%;" v-if="form.numType!=4">
					<el-select v-model="form.numFormat" style="width: 80%;">
						<el-option label="加入顺序" value="1" />
						<el-option label="入学年份+班级+学号" value="2" />
						<el-option label="年级+班级+学号" value="3" />
					</el-select>
				</el-form-item>
				<el-form-item label="其他信息" class="other" style="width: 100%;">
					<el-input v-model="form.Name" placeholder="请输入姓名" style="width:80%;margin-bottom:20px"></el-input>
					<el-input v-model="form.Mobile" placeholder="请输入手机号" :maxlength="11" @blur="ChangeMobile"
						style="width:80%;margin-bottom:20px"></el-input>
					<el-input v-model="form.Account" placeholder="请输入账号(最少五位数)" :minlength="5" @blur="verify"
						style="width:80%;margin-bottom:20px"></el-input>
					<el-input v-model="form.Password" placeholder="请输入密码" show-password style="width:80%"></el-input>
				</el-form-item>
				<el-form-item style="width: 80%;margin-left: 20px;">
					<el-button @click="dialogFormVisible = false">取消</el-button>
					<el-button type="primary" @click="onSubmit">添加</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<el-dialog title="导入数据" :visible.sync="dialogExcel" top="50px" :close-on-click-modal="false" width="30%">
			<el-form :model="form" ref="form" label-position="right" label-width="70px">
				<el-form-item label="省" style="width: 100%;">
					<el-select v-model="form.Province" placeholder="省" @change="province" style="width: 80%;">
						<el-option v-for="item in provinceList" :key="item.ID" :label="item.Name" :value="item.ID">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市" style="width: 100%;">
					<el-select v-model="form.City" placeholder="市" @change="city" style="width: 80%;">
						<el-option v-for="item in cityList" :key="item.ID" :label="item.Name" :value="item.ID">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区" style="width: 100%;">
					<el-select v-model="form.Area" placeholder="区" @change="area" style="width: 80%;">
						<el-option v-for="item in areaList" :key="item.ID" :label="item.Name" :value="item.ID">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="学校" style="width: 100%;">
					<el-select v-model="form.SchoolId" placeholder="请选择学校" style="width: 80%;">
						<el-option v-for="item in schoolListt" :key="item.ID" :label="item.SchoolName" :value="item.ID">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="数据导入">
					<el-upload ref="upload" class="file" :action="url" :file-list="file" :auto-upload="false"
						:on-success="change" :limit="1" :headers="{'token':token}" :data="form">
						<el-button slot="trigger" size="small" type="primary">选取文件</el-button>
						<el-button @click="dialogExcel = false">取消</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmitee">提交</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<el-dialog title="导入卡号" :visible.sync="dialogExcelCard" top="50px" :close-on-click-modal="false" width="30%">
			<el-form :model="formCard" ref="form" label-position="right" label-width="70px">
				<el-form-item label="数据导入">
					<el-upload ref="uploadCard" class="file" :action="urlCard" :file-list="fileCard" :auto-upload="false"
						:on-success="changeCard" :limit="1" :headers="{'token':token}" :data="formCard">
						<el-button slot="trigger" size="small" type="primary">选取文件</el-button>
						<el-button @click="dialogExcelCard = false">取消</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmiteeCard">提交</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="设置数据来源" :visible.sync="dialogSource" top="50px" :close-on-click-modal="false" width="30%">
			<el-form :model="form" ref="form" label-position="right" label-width="170px">
				<el-form-item label="数据来源" style="width: 100%;text-align:left;">
					<el-select v-model="dataSource" style="width: 80%;">
						<el-option label="API" value="api" />
						<el-option label="U盘" value="upan" />
					</el-select>
				</el-form-item>
				<el-form-item label="是否传输学生数据" style="width: 100%;text-align:left;" v-if="dataSource=='api'">
					<el-select v-model="coreData" style="width: 80%;">
						<el-option label="是" value="1" />
						<el-option label="否" value="2" />
					</el-select>
				</el-form-item>
				<el-form-item style="width: 100%;text-align:left;">
					<el-checkbox @change="handleChangeCreateByNum" v-model="createByNum" true-label="1" false-label="2">允许使用学号创建学生</el-checkbox>
				</el-form-item>
				<el-form-item style="width: 100%;text-align:left;" v-if="createByNum==1">
					<el-radio-group v-model="numType">
						<el-radio label="4">使用4-6位学号</el-radio>
						<el-radio label="9">使用9位以上学号</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="短编号生成方式" style="width: 100%;text-align:left;" v-if="numType!=4">
					<el-select v-model="numFormat" style="width: 80%;">
						<el-option label="加入顺序" value="3" />
						<el-option label="入学年份+班级+学号" value="1" />
						<el-option label="年级+班级+学号" value="2" />
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmitSource">提交</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="设置卡号前缀" :visible.sync="setCardNoVisible" :destroy-on-close="true" top="50px"
			:close-on-click-modal="false" width="30%">
			<el-form :model="form" ref="form" label-position="right" label-width="170px">
				<el-form-item label="前缀" style="width: 100%;">
					<el-input-number :min="0" :max="999999" v-model="cardNo" placeholder="请输入前缀4-6数字" style="width:80%"></el-input-number>
				</el-form-item>
				<el-form-item style="width: 80%;margin-left: 20px;">
					<el-button @click="setCardNoVisible = false">取消</el-button>
					<el-button type="primary" @click="onSetCardNo">添加</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		kidHead,
		schoolList,
		provinceCityArea,
		schoolAdd,
		classTypeLevel,
		excelAdd,
		excelSchoolList,
		schoolSource,
		makeKid
	} from '@/api/adminApi.js'
	import md5 from 'js-md5'
	import request from '@/api/request.js'
	export default {
		data() {
			return {
				setCardNoVisible: false,  //设置卡号前缀弹窗
				cardNo: '',   //卡号前缀
				cardNoId: '',  // 卡号前缀学校id
				keyword: '', //搜索关键字
				dialogSource: false, //数据来源弹窗
				dataSource: 'api', //数据来源
				createByNum: '2', //是否使用学号创建学生
				numFormat: '1', // 学号格式
				sourceData: null,
				numType: '9', // 学号格式类型   4:允许4-6位   9:允许9位及以上
				formCard: {},
				form: {
					core_data:'1',
					Province: '', // 省份
					City: '', // 城市
					Area: '', // 区域
					School: '', // 学校
					TypeId: '', // 学校类别
					Name: '', // 联系人
					Mobile: '', // 手机号
					Account: '', // 账号
					Password: '', // 密码
					SchoolId: '', // 学校id
					stable: '0', // 学校单独表
					xuenian: '6', // 学校学年
					source: 'api', //数据源
					 onlyStuNum: '2', // 是否允许仅学号创建学生 2:不允许  
					  numType: '9',
					  numFormat: '1'
				},
				schoolData: [], // 学校列表
				classtypelevelData: [], //幼儿园级别
				classtypelevelList: [], //幼儿园级别
				dialogFormVisible: false, // 添加学校弹窗
				dialogExcel: false, //excel导入
				dialogExcelCard: false, // excel导入卡号
				currpage: 1, // 初始页
				pagesize: 12, // 每页显示条目个数
				CountNum: 0, // 总条数
				loading: false, //加载框
				check: false, // 账号校验
				provinceList: [], // 省
				cityList: [], // 市
				cityListCopy: [], // 市 副本
				areaList: [], // 区
				areaListCopy: [], // 区 副本
				schoolList: [], // 校
				schoolListt: [], // 校
				categoryList: [], // 学校类别
				Regex: /[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/, // 字母数字校验
				regexs: /^1[3456789]\d{9}$/, // 手机号正则
				Telcheck: false,
				Numcheck: false,
				urlCard: this.$url + '/erature/kidImp', //上传文件请求adminApi
				url: this.$url + '/top/excelAdd', //上传文件请求adminApi
				file: [], //上传的file数组
				fileCard: [], 
				token: localStorage.getItem('Token'), //token
				coreData: 1,
			}
		},
		mounted() {
			this.schoolSerchList(1), // 获取第一页学校列表
				this.classTypeLevel(), //获取幼儿园级别
				this.provinceCityArea() // 总后台省市区学校接口
		},
		methods: {
			onSetCardNo() {
				
				
				
				kidHead({
					kid_head: this.cardNo,
					school_id: this.cardNoId
				}).then(res=>{
					if (res.status.code == 1) {
						this.$message({
							message: '设置成功',
							type: 'success'
						})
						this.setCardNoVisible = false
						this.schoolSerchList(1)
						this.cardNo = ''
					} else {
						this.$message({
							message: res.status.msg,
							type: 'none'
						})
					}
				})
			},
			setCardNo(data) {
				this.cardNoId = data.ID
				this.setCardNoVisible = true
			},
			createCardNo(data) {
				makeKid({school_id: data.ID}).then(res=>{
					if (res.status.code == 1) {
						this.$message({
							message: '生成成功',
							type: 'success'
						})
						this.schoolSerchList(1)
					} else {
						this.$message({
							message: res.status.msg,
							type: 'none'
						})
					}
				})
			},
            handleChangeCreateByNum(e) {
                if (e==2) this.numType = '9'
            },
			// 提交数据来源
			onSubmitSource(item) {
				schoolSource({
					school_id: this.sourceData.ID,
					source: this.dataSource,
					onlyStuNum: this.createByNum==2 ? 2 : this.numType,
                    shortNumRule: this.createByNum==1 && this.numType == 4 ? 0 : this.numFormat,
					core_data: this.coreData
				}).then(res => {
					this.dialogSource = false
					if (res.status.code == 1) {
						this.$message({
							message: '设置成功',
							type: 'success'
						})
						this.schoolSerchList(1)
					} else {
						this.$message({
							message: res.status.msg,
							type: 'none'
						})
					}
				})
			},
			//设置数据来源
			setSource(item) {
				this.createByNum = item.onlyStuNum == 2 ? '2' : '1'
				this.numType = item.onlyStuNum==2 ? '9' : item.onlyStuNum
				this.dataSource = item.source
				this.sourceData = item
				this.numFormat = item.shortNumRule
				this.coreData = item.core_data
				this.dialogSource = true
			},
			seachLevel(item) { //点击查看年级按钮
				this.$router.push({
					name: "levelList",
					params: {
						schoolId: item.ID,
						schoolTypeId: item.SchoolTypeId
					}
				})
			},
			seachStudent(item) { //点击查看学生按钮
				this.$router.push({
					name: "studentList",
					params: {
						schoolId: item.ID,
						levelId: 0,
						classId: 0
					}
				})
			},
			provinceCityArea() { // 总后台省市区学校接口
				provinceCityArea().then(res => {
					this.categoryList = res.data.schoolType // 学校类别
					//console.log(this.categoryList)
					this.form.TypeId = res.data.schoolType[1].ID // 学校类别默认值
					this.provinceList = res.data.province // 省
					this.cityList = res.data.city // 市
					this.cityListCopy = res.data.city // 市 副本
					this.areaList = res.data.area // 区
					this.areaListCopy = res.data.area // 区
				}).catch(err => {
					console.log(err)
				})
			},
			classTypeLevel() { // 总后台年级级别信息接口
				classTypeLevel().then(res => {
					this.classtypelevelList = res.data.filter(item => { // 年级级别信息过滤，只保留幼儿园
						return item.schoolTypeId == '1'
					})
				}).catch(err => {
					console.log(err)
				})
			},
			onSubmit(e) { // 表单提交
				if (this.classtypelevelData.length > 0) {
					this.form.classtypelevel = this.classtypelevelData.join('#')
				}
				
                
				//console.log(this.form)
				const datas = {
					province: this.form.Province, //	省ID
					city: this.form.City, //	市ID
					area: this.form.Area, //	区ID
					schoolName: this.form.School, //	学校名称
					schoolType: this.form.TypeId, //	学校类型ID
					classtypelevel: this.form.classtypelevel, //  幼儿园级别
					username: this.form.Name, // 	姓名
					tel: this.form.Mobile, //	手机号
					account: this.form.Account, // 账号
					password: md5(this.form.Password), // 密码
					stable: this.form.stable, // 单独学校
					xuenian: this.form.xuenian, // 单独学校
					source: this.form.source, // 数据源
					onlyStuNum: this.form.onlyStuNum == 1 ? this.form.numType : 2, // 是否学号创建
                    shortNumRule: this.form.numType == 4 && this.form.onlyStuNum==1 ? 0 : this.numFormat // 学号生成规则
				}
				var arrs = Object.values(this.form).filter(item => {
					if (item !== '') {
						return true // 过滤掉为空的项
					}
				})
				if (arrs.length >= 9 && this.Telcheck && this.Numcheck) {
					schoolAdd(datas).then(res => {
						if (res.status.code == 1) {
							this.$message({
								message: '创建成功',
								type: 'success'
							})
							this.form = {
								stable: '0',
								xuenian: '6'
							} // 清空
							this.dialogFormVisible = false;
							this.schoolSerchList(1);
						} else {
							this.$message.error(res.status.msg)
						}
					}).catch(err => {
						console.log(err)
					})
				} else if (!this.Regex.test(this.form.Account)) {
					this.$message.error('账号须包含字母和数字')
				} else if (!this.regexs.test(this.form.Mobile)) {
					this.$message.error('手机号有误')
				} else {
					this.$message.error('请完善表单')
				}
			},
			province(val) { // 省份选择
				this.form.City = '' // 省份变动清空城市
				//this.form.SchoolId = '' // 区域变动清空学校
				this.cityList = this.cityListCopy //查询到所有城市
				this.cityList = this.cityList.filter(item => item.ProvinceId == val) //filter根据省的id 查到对应的城市
			},
			city(val) { // 城市选择
				this.form.Area = '' // 城市变动清空区域
				//this.form.SchoolId = '' // 区域变动清空学校
				this.areaList = this.areaListCopy
				this.areaList = this.areaList.filter(item => item.CityId == val)
			},
			area(val) { // 区域选择
				this.form.SchoolId = ""; // 区域变动清空学校
				this.schoolListt = [] // 区域变动清空学校
				const datas = {
					schoolid: val, //注意这是小写的 和 form里的不一样
				}
				excelSchoolList(datas).then(res => {
					this.schoolListt = res.data.List // res.data.List查询到该地区所有的学校
					//console.log(res.data)
					this.schoolListt = this.schoolListt.filter(item => item.Area == val) //根据地区id查到满足的学校
				}).catch(err => {
					console.log(err)
				})
			},
			ChangeMobile() { // 输入手机号失去焦点
				if (!this.regexs.test(this.form.Mobile)) {
					this.$message.error('手机号有误')
					this.Telcheck = false
				} else {
					this.Telcheck = true
				}
			},
			verify() { // 账号校验
				if (!this.Regex.test(this.form.Account)) {
					this.check = true
					this.Numcheck = false
				} else {
					this.check = false
					this.Numcheck = true
				}
			},
			schoolSerchList(currpage) { // 学校列表
				this.loading = true
				const data = {
					page: currpage, // 当前页数
					limit: this.pagesize, // 每页条数
					school_name: this.keyword
				}
				schoolList(data).then(res => {
					//console.log(res)
					this.schoolData = res.data.List // 学校列表
					this.CountNum = parseInt(res.data.CountNum) // 总条数
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},
			handleCurrentChange(val) { // 分页
				this.schoolSerchList(val)
				this.currpage = val
			},
			async onSubmitee() { //提交按钮
				if (this.form.SchoolId) {
					this.$refs.upload.submit(); //手动上传
				} else {
					this.$message.error('请选择学校')
				}
			},
			async onSubmiteeCard() { //提交按钮
				this.$refs.uploadCard.submit(); //手动上传
			},
			changeCard(res) {
				if (res.status.code != 1) {
					this.$message.error(res.status.msg);
					this.file = [];
				} else {
					this.$message({
						message: '导入成功',
						type: 'success'
					})
					this.formCard = {};
					this.fileCard = [];
				}
			},
			change(res) { //上传文件成功回调
				//console.log(res.err);
				if (res.err == 10) {
					this.$message.error('服务器错误 无法上传');
					this.file = [];
				} else if (res.err == 9) {
					this.$message.error('不是Excel文件，仅支持xls xlsx');
					this.file = [];
				} else if (res.err == 8) {
					this.$message.error(' 检查第2行 班级是否正确');
					this.file = [];
				} else if (res.err == 7) {
					this.$message.error(' 检查第' + res.num + '行');
					this.file = [];
				} else if (res.err == 6) {
					this.$message.error(' 检查第' + res.num + '行 班级 姓名是否存在');
					this.file = [];
				} else if (res.err == 5) {
					this.$message.error(' 检查第' + res.num + '行 班级是否创建 ');
					this.file = [];
				} else if (res.err == 4) {
					this.$message.error(' 检查第' + res.num + '行 年级是否创建 ');
					this.file = [];
				} else if (res.err == 3) {
					this.$message.error(' 班级有后台程序不符 请联系程序人员 ');
					this.file = [];
				} else if (res.err == 1) {
					this.$message({
						message: '导入成功',
						type: 'success'
					})
					this.form = {};
					this.file = [];
					this.schoolListt = [];
					this.schoolSerchList(1);
				}
			},
		}
	}
</script>

<style scoped>
</style>